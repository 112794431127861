// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export function moveObjToSortedArray(obj: any, sortByKey: string): any[] {
  const tempArray: string[] = [];
  for (const key in obj) {
    tempArray.push(obj[key]);
  }
  // @ts-ignore (fix me please, do not replicate)
  tempArray.sort((a, b) => b[sortByKey] - a[sortByKey]);
  return tempArray;
}
