import consumerApi from "api/services/consumer-api";
import { Dispensary } from "custom-types/Dispensary";
import { Strain } from "custom-types/Strain";
import logError from "utils/logError";

type StrainsApiResponse = {
  strains: Strain[];
  totalCount: number;
  error?: boolean;
};

type DispensariesApiResponse = {
  dispensaries: Dispensary[];
  metadata: {
    totalCount: number;
  };
  error?: boolean;
};

type DispensariesResponse = {
  dispensaries: Dispensary[];
  totalCount: number;
  error?: boolean;
};

export const getUserFavoritedStrains = async (
  currentPage = 1,
  sort?: string,
  take?: number,
): Promise<StrainsApiResponse> => {
  const params = {
    ...(take && { take }),
    skip: (currentPage - 1) * 20,
    ...(sort && { sort }),
  };
  try {
    const response = await consumerApi.get<StrainsApiResponse>(
      `/api/profile/v1/favorite_strains`,
      {
        params,
      },
    );

    return response.data;
  } catch (e) {
    if (e.statusCode !== 401) {
      logError(e.message, {
        functionName: "getUserFavoritedStrains",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return {
      error: true,
      strains: [],
      totalCount: 0,
    };
  }
};

export const getUserFollowedDispensaries =
  async (): Promise<DispensariesResponse> => {
    try {
      const { data } = await consumerApi.get<DispensariesApiResponse>(
        `/api/profile/v1/followed_dispensaries`,
      );

      const { dispensaries, metadata } = data;
      const { totalCount } = metadata;
      return {
        dispensaries,
        totalCount,
      };
    } catch (e) {
      if (e.statusCode !== 401) {
        logError(e.message, {
          functionName: "getUserFollowedDispensaries",
          service: "consumer",
          statusCode: e.statusCode,
        });
      }
      return {
        dispensaries: [],
        error: true,
        totalCount: 0,
      };
    }
  };
