import { TerpDataType } from "custom-types/Strain";

export const WEIGHTS = [
  {
    sortValue: 0.125,
    value: "1/8 oz",
  },
  {
    sortValue: 0.035,
    value: "1g",
  },
  {
    sortValue: 0.25,
    value: "1/4 oz",
  },
  {
    sortValue: 0.5,
    value: "1/2 oz",
  },
  {
    sortValue: 1,
    value: "1 oz",
  },
];

export const ITEMS_PER_STRAIN_REVIEW_PAGE = 8;

export const terpData: { [key: string]: TerpDataType } = {
  Caryophyllene: {
    description:
      "Is your nose tingling? Spicy, peppery, good-funky, pungent, diesel aromas. Known for relaxation and anxiety relief. Also found in cloves, cinnamon.",
    flavor: "pepper",
  },
  Humulene: {
    description:
      "Distinct spicy, woody aromas. Almost never the dominant terpene, but still abundant in strains. Anti-inflammatory, antifungal, appetite suppressant.",
    flavor: "hoppy",
  },
  Limonene: {
    description:
      "Fruity, woodsy, pine aromas. Elevated mood, good medium energy, stress relief. Also found in juniper, fruit rinds, and nice-smelling cleaning products.",
    flavor: "citrus",
  },
  Linalool: {
    description:
      "Floral aromas. Emerging therapeutic effects include improved mood, relaxation, and calm. Also found in lavender.",
    flavor: "floral",
  },
  Myrcene: {
    description:
      "Earthy, peppery aromas and flavors. The most common terpene. Ranges from a little bit chill to sedative chill. Also found in thyme, lemongrass.",
    flavor: "herbal",
  },
  Ocimene: {
    description:
      "Beautifully sweet, herbaceous, citrusy and woody. Uplifting effects. Frequently used in perfume. Also found in mint, parsley, orchids.",
    flavor: "mint",
  },
  Pinene: {
    description:
      "Big pine forest aromas. Most abundant terp in the natural world. Good for chilling out. Reviewers like it for anxiety and pain relief.",
    flavor: "pine",
  },
  Terpinolene: {
    description:
      "Fresh and complex aromas of pine, florals, herbs, and citrus. Energetic and creative. Also found in lilacs, nutmeg, and cumin.",
    flavor: "fruity",
  },
};

export const FLAVORS_EFFECTS_DISCLAIMER =
  "This info is sourced from our readers and is not a substitute for professional medical advice. Seek the advice of a health professional before using cannabis for a medical condition.";
