import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import {
  getUserFavoritedStrains,
  getUserFollowedDispensaries,
} from "api/requests/getUserFavorites";
import toggleFavoriteStrain from "api/requests/toggleFavoriteStrain";
import { Action, Category } from "constants/events";
import { SnackBarMessages, useSnackBar } from "context/SnackBarContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import {
  USER_FAVORITED_STRAINS_RESULTS,
  USER_FAVORITES_LOAD,
  USER_FOLLOWED_DISPENSARIES_RESULTS,
  UserFavoritesLoadAction,
  UserFavoritesResultsAction,
  UserFollowedDispensariesAction,
} from "redux/reducers/user";
import {
  getIsLoggedIn,
  getUserFavoriteStrains,
  getUserFollowDispensaries,
} from "redux/selectors/user";
import { generateSignUpUrl } from "utils/generateSsoUrls";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

interface FavoriteStrainProps {
  doesUserFollow: boolean;
  strainId: number;
  favoriteGaLabel?: string;
  unFavoriteGaLabel?: string;
}

const useUserFavorites = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const countryCode = useDomainCountryCode();
  const favoritedStrains = useSelector(getUserFavoriteStrains);
  const followedDispensaries = useSelector(getUserFollowDispensaries);
  const [favoritesEnabled, setFavoritesEnabled] = useState(true);
  const { route, query, replace, asPath } = useRouter();
  const { path } = parseNextRouterAsPath(asPath);

  const { publishEvent } = useEventTracker();

  const retrieveFavorites = async () => {
    dispatch<UserFavoritesLoadAction>({
      type: USER_FAVORITES_LOAD,
    });

    const { strains, totalCount: strainsTotalCount } =
      await getUserFavoritedStrains(1).then((userFavoritedStrainData) => {
        if (userFavoritedStrainData.error) {
          // lets prevent user frustration by removing the favorite button
          // if we get an error back
          setFavoritesEnabled(false);
        }
        return userFavoritedStrainData;
      });

    dispatch<UserFavoritesResultsAction>({
      favoritedStrains: {
        strains,
        totalCount: strainsTotalCount,
      },
      type: USER_FAVORITED_STRAINS_RESULTS,
    });

    const { dispensaries, totalCount: dispensariesTotalCount } =
      await getUserFollowedDispensaries().then(
        (userFollowedDispensariesData) => {
          if (userFollowedDispensariesData.error) {
            setFavoritesEnabled(false);
          }
          return userFollowedDispensariesData;
        },
      );
    dispatch<UserFollowedDispensariesAction>({
      followedDispensaries: {
        dispensaries,
        totalCount: dispensariesTotalCount,
      },
      type: USER_FOLLOWED_DISPENSARIES_RESULTS,
    });
  };

  const { addSnackBarItem } = useSnackBar();

  const favoriteStrain = async ({
    doesUserFollow,
    strainId,
    favoriteGaLabel = "Favorite Strain",
    unFavoriteGaLabel = "Unfavorite Strain",
    ...customDimensions
  }: FavoriteStrainProps) => {
    const response = await toggleFavoriteStrain(
      countryCode,
      doesUserFollow,
      strainId,
    );

    if (response !== "not signed in") {
      await retrieveFavorites();
    }
    if (response === "followed") {
      publishEvent({
        action: Action.click,
        category: Category.favorite,
        label: favoriteGaLabel,
        ...customDimensions,
      });
      addSnackBarItem(SnackBarMessages.favoriteAdded);
    } else if (response === "unfollowed") {
      addSnackBarItem(SnackBarMessages.favoriteRemoved);
      publishEvent({
        action: Action.click,
        category: Category.favorite,
        label: unFavoriteGaLabel,
        ...customDimensions,
      });
    } else if (response === "not signed in") {
      window.location.href = generateSignUpUrl(
        countryCode,
        `${path}?strainId=${strainId}&favoriteGaLabel=${favoriteGaLabel}&unFavoriteGaLabel=${unFavoriteGaLabel}`,
      );
    }
    return response;
  };

  const checkIfUserFollows = (slug: string) => {
    return favoritedStrains?.strains?.length
      ? favoritedStrains?.strains?.findIndex(
          (favStrain) => favStrain?.slug === slug,
        ) > -1
      : false;
  };

  const useInitiateFavorites = () => {
    useEffect(() => {
      if (query.strainId && typeof query.strainId === "string") {
        const { favoriteGaLabel, unFavoriteGaLabel } = query;
        favoriteStrain({
          doesUserFollow: false,
          favoriteGaLabel: favoriteGaLabel?.toString(),
          strainId: Number(query.strainId),
          unFavoriteGaLabel: unFavoriteGaLabel?.toString(),
        }).then(() => {
          const newQuery = query;
          delete newQuery.strainId;
          replace(
            {
              pathname: route,
              query: newQuery,
            },
            undefined,
            { shallow: true },
          );
        });
      }
    }, [query]);

    useEffect(() => {
      if (
        isLoggedIn &&
        (!favoritedStrains?.strains || !followedDispensaries?.dispensaries)
      ) {
        retrieveFavorites();
      }
    }, [isLoggedIn]);
  };

  return {
    checkIfUserFollows,
    favoriteStrain,
    favoritedStrains,
    favoritesEnabled,
    followedDispensaries,
    retrieveFavorites,
    useInitiateFavorites,
  };
};

export default useUserFavorites;
