import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
} from "react";

import { AddToBagModal } from "components/AddToBagModal";

export type AddToBagContextProps = {
  selectedVariantId?: number;
  setSelectedVariantId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  buttonRef: React.MutableRefObject<null> | null;
  setButtonRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<null>>
  >;
  addingToBag: boolean;
  setAddingToBag: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddToBagContext = createContext<AddToBagContextProps>({
  addingToBag: false,
  buttonRef: null,
  setAddingToBag: () => {},
  setButtonRef: () => {},
  setSelectedVariantId: () => {},
});
const { Provider } = AddToBagContext;

export const AddToBagProvider = ({ children }: { children: ReactNode }) => {
  const defaultRef = useRef(null);
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [addingToBag, setAddingToBag] = useState(false);
  const [buttonRef, setButtonRef] = useState(defaultRef);

  return (
    <Provider
      value={{
        addingToBag,
        buttonRef,
        selectedVariantId,
        setAddingToBag,
        setButtonRef,
        setSelectedVariantId,
      }}
    >
      {children}

      <AddToBagModal
        immediatelyAddToBag={true}
        onDismiss={() => {
          setSelectedVariantId(undefined);
          setAddingToBag(false);
        }}
        returnFocusRef={buttonRef}
        variantId={selectedVariantId}
      />
    </Provider>
  );
};

export const useAddToBagContext = () => useContext(AddToBagContext);

export default AddToBagContext;
