import consumerApi from "api/services/consumer-api";
import consumerApiCa from "api/services/consumer-api-ca";
import logError from "utils/logError";

const toggleFavoriteStrain = async (
  countryCode: string,
  userFollows: boolean,
  strainId: number,
) => {
  const host = countryCode === "CA" ? consumerApiCa : consumerApi;
  try {
    await host({
      data: {
        followeeId: strainId,
        followeeType: "strain",
      },
      method: userFollows ? "delete" : "post",
      url: `/api/followers/v1/`,
    });

    return userFollows ? "unfollowed" : "followed";
  } catch (e) {
    if (e.statusCode === 401) {
      return "not signed in";
    } else {
      logError(e.message, {
        functionName: "toggleFavoriteStrain",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }
  }
};

export default toggleFavoriteStrain;
