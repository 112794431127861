import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import getSearchResults from "api/requests/getSearchResults";
import { WEIGHTS } from "constants/strains";
import { Coordinates } from "custom-types/Coordinates";
import { MenuItem } from "custom-types/MenuItem";
import { getCoordinates } from "redux/selectors/location";
import formatImgixUrl from "utils/image/formatImgixUrl";

import useDomainCountryCode from "./useDomainCountryCode";

type Weight = {
  value: string;
  sortValue: number;
};

type Weights = Weight[];
export const useGetAvailableMenuItems = (
  strainName: string,
  defaultSort = "distance",
) => {
  const [items, setItems] = useState<MenuItem[]>([]);
  const [weights, setWeights] = useState<Weights>([]);
  const [initialWeight, setInitialWeight] = useState("");
  const [loading, setLoading] = useState(false);

  const coordinates = useSelector(getCoordinates);
  const countryCode = useDomainCountryCode();

  useEffect(() => {
    async function findAvailableItems() {
      let data;
      for (const weight of WEIGHTS) {
        const response = await getAvailableItems(
          strainName,
          weight.value,
          defaultSort,
          coordinates,
          countryCode,
        );

        if (response?.menuItems?.length) {
          data = response;
          setInitialWeight(weight.value);
          break;
        }
      }
      return data;
    }

    findAvailableItems()
      .then((data) => {
        if (data?.menuItems?.length) {
          setItems(data.menuItems);
        }
        // availableWeights are returned each time, but they won't change so we won't do this every time
        if (data?.availableWeights?.length && !weights?.length) {
          // create a new array with sortValue
          const mappedWeights = data?.availableWeights
            .map((weight) => {
              const foundWeight = WEIGHTS.find((item) => item.value === weight);
              if (foundWeight !== undefined) {
                return foundWeight;
              }
              return undefined;
            })
            .filter((w) => !!w)
            .sort((a, b) => (a?.sortValue || 0) - (b?.sortValue || 0));

          setWeights(mappedWeights as Weight[]);
        }
      })
      .then(() => setLoading(false));
  }, []);

  return {
    initialWeight,
    menuItemWeights: weights,
    menuItems: items,
    menuItemsLoading: loading,
  };
};

export const getAvailableItems = async (
  name: string,
  weight: string,
  sort = "distance",
  coordinates: Coordinates,
  countryCode: string,
) => {
  let params = {
    collapse: "dispensary_id",
    "filter[max_distance]": "30",
    "filter[normalized_quantity]": weight,
    "filter[pickup_available]": "true",
    "filter[product_category]": "Flower",
    "filter[strain_name]": name,
    "filter[type]": "menu_item",
    "filter[unit]": "g",
  };

  if (sort === "price") {
    params = {
      ...params,
      ...{
        sort: {
          "0": {
            price: "asc",
          },
        },
      },
    };
  }

  const { menuItem: menuItems, availableFilters } = await getSearchResults(
    params,
    coordinates,
    100,
    countryCode,
    3,
  );

  const availableWeights =
    availableFilters.find((filter) => filter.name === "normalized_quantity")
      ?.values || [];

  menuItems.forEach((item, i) => {
    if (item?.dispensary?.logoUrl) {
      menuItems[i].dispensary.logoUrl = formatImgixUrl(item.dispensary.logoUrl);
    }
  });

  return { availableWeights, menuItems };
};
