import React from "react";

import Placeholder, { Rect } from "components/Placeholder";

export const ComparisonShoppingTablePlaceholder: React.FC = () => (
  <div>
    <div className="md:flex justify-between items-baseline">
      <div className="flex md:justify-center mb-lg">
        {["30", "50", "40", "50", "50"].map((w, i) => (
          <Placeholder
            key={i}
            width={w}
            height="30"
            className="mx-xs border border-light-grey rounded"
          >
            <Rect width="100%" height="100%" />
          </Placeholder>
        ))}
      </div>
      <Placeholder
        width="100"
        height="20"
        className="mx-xs border border-light-grey rounded mb-md md:mb-none"
      >
        <Rect width="100%" height="100%" />
      </Placeholder>
    </div>
    <div>
      {["145", "160", "155"].map((h, i) => (
        <Placeholder
          key={i}
          width="100%"
          height={h}
          className="mb-lg border border-light-grey rounded"
        >
          <Rect width="100%" height="100%" />
        </Placeholder>
      ))}
    </div>
  </div>
);
